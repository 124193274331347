:root {
  --navbar-height: 65px;
  --footer-height: 70px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  background-color: #FFF;
  color: #111;
  margin: 0px;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--footer-height);
  background-color: #555;
  color: #CCC;
}

main {
  max-width: 60em;
  margin: 0px auto;
}



p {
  margin: 0.6em 1.2em;
  display: block;
}

main .home--links {
  text-align: center;
  line-height: 3em;
  padding: 1em;

}
main .home--links a {
  background: linear-gradient(rgb(255, 255, 255), rgb(187, 187, 187));
  border-radius: 0.8em;
  font-weight: bold;
  color: #000;
  display: flex;
  height: 3em;
  margin: auto;
  max-width: 14em;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid #000;
}

main ul>li {
  margin-bottom: 1em;
  list-style: none;
}

.contact--phone {
  border-radius: 0.8em;
  border:1px solid #000;
  color: #444;
  margin: 0px auto;
  max-width: 11em;
  padding: 0.6em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.2em;
  background: linear-gradient(#DFD, #6C5);
  font-weight: 700;
}

hr.hr--short {
  width: 50%;
}

div.contact--phone img {
  height: 1.8em;
}

main > h1 {
  color: #222;
  padding: 0.5em 0px 0px 1em;
  line-height: 0.0em;
  font-size: 1.4em;
  font-weight: 800;
  margin-top: 0px;
  width: 100%;
  letter-spacing: -0.03em;
  text-align: center;
}

.wrapper {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}

.wrapper--center {
  justify-content: center;
}

.profile--container {
  color: #222;
  border-radius: 0.9em;
  background: linear-gradient(#AAA, #EEE 10em);
  border: 0.1em solid #AAA;
  display: flex;
  flex-direction: column;
  margin: 0.8em;
  padding: 1em;
  width: 12em;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  cursor: default;
}

.profile--photo {
  width: 10em;
  aspect-ratio: 1;
  border: 0.1em solid #888;
  border-radius: 50%;
  align-self: center;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.profile--name {
  font-weight: 700;
  letter-spacing: -0.03em;
  text-align: center;
  margin-top: 0.6em;
  color: #555;
}

.profile--role {
  text-align: center;
  color: #999;
  letter-spacing: -0.03em;
  font-weight: 600;
  font-size: 0.7em;
}

.profile--description {
  color: #666;
  font-size: 0.7em;
  margin-top: 0.6em;
  font-style: italic;
  font-weight: 600;
  text-align: center;
}

/* TIMETABLE */
.ot--table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-align: center;
  font-size: 1.1em;
  margin: 0px auto;
  max-width: 25em;
  width: 100%;
}

.ot--table > div {
  padding: 0px 1em;
}

.ot--o:nth-child(1), .ot--c:nth-child(2) {
  font-weight: bold;
}

.ot--d {
  grid-column: 1/2;
  text-align: right;
  border: 1px solid #CCC;
}

.ot--o {
  grid-column: 2/3;
  border: 1px solid #CCC;
}

.ot--c {
  grid-column: 3/4;
  border: 1px solid #CCC;
}

.ot--x {
  grid-column: 2/4;
  border: 1px solid #CCC;
}

.ot--today {
  background-color: rgb(174, 255, 163);
  font-weight: bold;
}

@media (max-width: 480px) {
  .profile--container {
    width: 90%;
  }

  .ot--table > div.ot--d {
    border-left: none;
  }
  
  .ot--table > div.ot--c,
  .ot--table > div.ot--x {
    border-right: none;
  }

  .ot--table {
    grid-template-columns: 1fr 30% 30%;
    width: 100%;
    max-width: 100%;
    font-size: 100%;
  }
  .ot--table > div { padding: 0px 0px; }
  .ot--table > div.ot--d { padding: 0px 5%; }
}